import styled, { css, device, themeGet } from "@styled";
import blueWaveBg from "@data/images/bg/blue-wave-bg.webp";
import bluegradientbg from "@data/images/bg/bluegradientbg.png";
import orangewaveBg from "@data/images/bg/orangewaveBg.webp";

export const BoxSectionWrapper = styled.section`
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
  background: linear-gradient(
    320deg,
    ${themeGet("colors.secondaryDark")},
    ${themeGet("colors.secondary")}
  );
  ${device.medium} {
    padding: 166px 0;
  }
  .section-title {
    p {
      color: #fff;
    }
  }
  ${(props) =>
    props.layout === 1 &&
    css`
      background: linear-gradient(
        320deg,
        ${themeGet("colors.secondaryDark")},
        ${themeGet("colors.secondary")}
      );
    `}
     ${(props) =>
    props.layout === 7 &&
    css`
      background: linear-gradient(
        320deg,
        ${themeGet("colors.secondaryDark")},
        ${themeGet("colors.secondary")}
      );
        ${device.medium} {
    padding: 100px 0 0px;
  }
    `}
  ${(props) =>
    props.layout === 2 &&
    css`
      padding-top: 170px;
      background: url(${blueWaveBg});
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
    `}

${(props) =>
    props.layout === 3 &&
    css`
      background: linear-gradient(
        320deg,
        ${themeGet("colors.primaryDark")},
        ${themeGet("colors.primary")}
      );
      .section-title {
        h6 {
          color: #fff;
        }
        h2 {
          border-color: #fff; 
        }
        p {
          color: #fff;
        }
      }
    `}
${(props) =>
    props.layout === 4 &&
    css`
      padding-top: 170px;
      background: url(${orangewaveBg});
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
      .section-title {
        h6 {
          color: #fff;
        }
        h2 {
          border-color: #fff;
        }
        p {
          color: #fff;
        }
      }
    `}
     ${(props) =>
    props.layout === 5 &&
    css`
      padding: 60px 0 !important;
      background: url(${bluegradientbg});
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
      ${device.medium} {
        background-position: right top;
        padding: 100px 0 !important;
      }
        .vlbtn{
          margin: 70px auto 0px !important;
          .btn-learn-more {
              display: flex;
              width: fit-content;
              margin: 0 auto;
          }
        }
    `}
`;

export const BoxSectionInner = styled.div``;

export const BoxSectionWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;
  ${device.medium} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
export const BoxSectionWrapInner = styled.div`
  padding: 15px 0;
  border: 1px solid rgb(255 255 255 / 55%);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: rgb(255 255 255 / 11%);
  transition: ${themeGet("transition")};
  &:hover {
    box-shadow: 0 0 40px -16px rgb(255 255 255);
    transform: translateY(-3px);
  }
`;
export const BoxSectionIconwrap = styled.div`
  min-width: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const BoxSectionTextWrap = styled.div`
  border-left: 1px solid rgb(255 255 255 / 55%);
  padding-left: 20px;
  padding-right: 20px;
  h6 {
    margin: 20px 0;
    font-size: 22px;
    font-weight: bold;
  }
  h4 {
    margin: 20px 0;
    font-size: 20px;
    font-weight: bold;
    ${device.xxlarge} {
      font-size: 22px;
    }
  }
  p {
    color: rgb(255 255 255 / 70%);
    font-size: 14px;
    ${device.xlarge} {
      font-size: 15px;
    }
    ${device.xxlarge} {
      font-size: 17px;
    }
  }
  ul {
    li {
      color: #ccb8fe;
      font-size: 15px;
      margin-bottom: 12px;
      line-height: 1;
      padding-left: 15px;
      position: relative;
      &::before {
        background: #ccb8fe !important;
      }
    }
  }
`;
export const BoxBottomImgWrap = styled.div`
  margin-bottom: -100px;
  text-align: center;
  padding-top: 70px;
  ${device.medium} {
    margin-bottom: -166px;
  }
`;

export const ButtonWrap = styled.div`
  text-align: center;
  margin-top: 40px;
  &.vlbtn {
    margin-top: 220px;
    margin-bottom: -100px;
  
    @media (max-width: 768px) {
      margin-top: 130px;
      margin-bottom: -70px;
    }
  }
  a {
    margin: 10px 15px;
    &:hover {
      background: ${themeGet("colors.primary")};
    }
  }
`;